import { useCallback } from 'react'

import { Navbar as BaseNavbar, Navbar2 as BaseNavbar2 } from 'decentraland-dapps/dist/containers'
import { NavbarPages } from 'decentraland-ui/dist/components/Navbar/Navbar.types'

import { config } from '../../config/config'

import './Navbar.css'
import { Props } from './Navbar.types'

const AUTH_URL = config.get('AUTH_URL')

export default function Navbar(props: Props) {
  const handleSignIn = useCallback(() => {
    window.location.replace(`${AUTH_URL}?redirectTo=${window.location.href}`)
  }, [])

  if (props.isNavbar2Enabled) {
    return (
      <BaseNavbar2 {...props} onSignIn={handleSignIn} withNotifications={false} activePage={NavbarPages.GOVERNANCE} />
    )
  }

  return <BaseNavbar {...props} onSignIn={handleSignIn} withNotifications={false} activePage="governance" />
}
