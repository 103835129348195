import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'balloon-css/balloon.min.css'
import TranslationProvider from 'decentraland-dapps/dist/providers/TranslationProvider'
import WalletProvider from 'decentraland-dapps/dist/providers/WalletProvider'
import { DclThemeProvider, lightTheme } from 'decentraland-ui2'
import 'decentraland-ui/dist/themes/alternative/light-theme.css'
import 'decentraland-ui/dist/themes/base-theme.css'
import 'decentraland-ui/lib/styles.css'
import 'semantic-ui-css/semantic.min.css'

import Layout from './components/Layout/Layout'
import { initStore } from './modules/store'
import GrantsPage from './pages/grants'
import GrantsRecipientsPage from './pages/grants/recipients'
import GrantsResourcesPage from './pages/grants/resources'

import IndexPage from './pages'
import './theme.css'

const queryClient = new QueryClient()
const basename = /^decentraland.(zone|org|today)$/.test(window.location.host) ? '/dao' : '/'

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={basename}>
        <Provider store={initStore()}>
          <WalletProvider>
            <DclThemeProvider theme={lightTheme}>
              <TranslationProvider locales={['en']}>
                <Layout>
                  <Routes>
                    <Route path="*" element={<IndexPage />} />
                    <Route path="/grants" element={<GrantsPage />} />
                    <Route path="/grants/recipients" element={<GrantsRecipientsPage />} />
                    <Route path="/grants/resources" element={<GrantsResourcesPage />} />
                  </Routes>
                </Layout>
              </TranslationProvider>
            </DclThemeProvider>
          </WalletProvider>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
